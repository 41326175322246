'use client';

import { useController, useFormContext } from 'react-hook-form';
import { useCallback, useMemo } from 'react';
import { useTranslations } from 'next-intl';
import debounce from 'lodash.debounce';

import { FragmentType, getFragmentData } from '@/lib/gql';
import { Form } from '@/lib/io-kit/Form';
import { VaultOption, VaultSelect } from '@/components/VaultSelect';
import { truncateValue } from '@/lib/truncate-value';
import {
  WalletConnectFormFragment,
  WalletConnectFormAddressFragment,
  WalletConnectLoginFormInputs,
  FieldIds,
} from '@/features/wallet-connect/page-logic';

const DEBOUNCE_INTERVAL = 350;

type Props = {
  vault: FragmentType<typeof WalletConnectFormFragment>;
  availableVaults: FragmentType<typeof WalletConnectFormFragment>[];
  address?: FragmentType<typeof WalletConnectFormAddressFragment>;
};

export function VaultInfo({ vault, availableVaults, address }: Props) {
  const t = useTranslations('Components.WalletConnect.WalletConnectForm');

  const { control } = useFormContext<WalletConnectLoginFormInputs>();
  const { field } = useController({ name: 'vaultId', control });

  const vaultData = getFragmentData(WalletConnectFormFragment, vault);
  const vaultsData = getFragmentData(WalletConnectFormFragment, availableVaults);
  const addressData = address ? getFragmentData(WalletConnectFormAddressFragment, address) : undefined;

  const vaultOptions: VaultOption[] = useMemo(
    () =>
      vaultsData.map((vaultOption) => {
        const vaultAddressData = vaultOption.details.visibleAssets.map((address) =>
          getFragmentData(WalletConnectFormAddressFragment, address),
        );

        const vaultAddressOnNetwork = vaultAddressData.find((address) => address.asset.id === addressData?.asset.id);
        const correspondingVault = vaultsData.find((v) => v.id === vaultOption.id);

        return {
          value: vaultOption.id,
          label: vaultOption.details.name,
          threshold: vaultOption.details.threshold,
          isSelected: vaultOption.id === vaultData.id,
          address: {
            id: vaultAddressOnNetwork?.id ?? '',
            balanceUsd: correspondingVault?.details.balanceUsd ?? '0',
            addressHash: truncateValue(vaultAddressOnNetwork?.addressHash ?? '', 5),
            balanceAsCoin: vaultAddressOnNetwork?.balanceAsCoin ?? '0',
            assetId: vaultAddressOnNetwork?.asset.id,
          },
        };
      }),
    [addressData, vaultData.id, vaultsData],
  );

  const handleSelectOption = useCallback(
    (newValue: unknown) => {
      const { value } = newValue as unknown as VaultOption;
      field.onChange(value);
    },
    [field],
  );

  const debouncedSearch = useMemo(
    () =>
      debounce(
        (inputValue: string, resolve: (options: VaultOption[]) => void) => {
          const filteredOptions = vaultOptions.filter((option) =>
            option.label.toLowerCase().includes(inputValue.toLowerCase()),
          );
          resolve(filteredOptions);
        },
        DEBOUNCE_INTERVAL,
        { trailing: true },
      ),
    [vaultOptions],
  );

  const search = useCallback(
    (inputValue: string) => new Promise<VaultOption[]>((resolve) => debouncedSearch(inputValue, resolve)),
    [debouncedSearch],
  );

  return (
    <Form.Group>
      <Form.Label>{t('vaultLabel')}</Form.Label>
      <VaultSelect
        defaultValue={vaultOptions.find((vault) => vault.isSelected)}
        inputId={FieldIds.Vault}
        id="wallet-connect-vault"
        data-testid="wallet-connect.form.vault"
        name={field.name}
        onChange={handleSelectOption}
        defaultOptions={vaultOptions}
        value={vaultOptions.find((vault) => vault.value === field.value)}
        openMenuOnFocus={false}
        loadOptions={search}
      />
    </Form.Group>
  );
}
